/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation ReadNotification($notificationId: ID!) {\n      notification {\n        readNotification(notification_id: $notificationId) {\n          success\n        }\n      }\n    }\n  ": types.ReadNotificationDocument,
    "\n    mutation ReadNotifications($input: ReadNotificationsInput) {\n      notification {\n        readNotifications(input: $input) {\n          success\n        }\n      }\n    }\n  ": types.ReadNotificationsDocument,
    "\n    mutation SeenUserNotifications {\n      notification {\n        seenUserNotifications {\n          success\n        }\n      }\n    }\n  ": types.SeenUserNotificationsDocument,
    "\n    mutation Accept($alias: String!) {\n      toc {\n        accept(alias: $alias) {\n          success\n        }\n      }\n    }\n  ": types.AcceptDocument,
    "\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  ": types.StarterDataDocument,
    "\n    query Notifications($input: GetNotificationsInput!) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n          total\n          cursor\n          notifications {\n            id\n            message\n            read_at\n            created_at\n            actor {\n              display_name\n              avatar_url\n            }\n            sourceable_type\n            sourceable_id\n            subject {\n              ... on Post {\n                id\n              }\n              ... on Comment {\n                id\n                post_id\n              }\n            }\n          }\n        }\n      }\n    }\n  ": types.NotificationsDocument,
    "\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  ": types.CountryConfigsDocument,
    "\n    query customerContent {\n      customerContent {\n        content {\n          ... on Pattern {\n            id\n            sku\n            url\n            createdAt\n            updatedAt\n          }\n        }\n      }\n    }\n  ": types.CustomerContentDocument,
    "\n    query Posts($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  ": types.PostsDocument,
    "\n    query loyalty {\n      loyalty {\n        points {\n          cursor\n          limit\n          points {\n            amount\n            created_at\n            type\n            description\n          }\n        }\n      }\n    }\n  ": types.LoyaltyDocument,
    "\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  ": types.PatternBySkuDocument,
    "\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  ": types.GetCountriesDataQueryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ReadNotification($notificationId: ID!) {\n      notification {\n        readNotification(notification_id: $notificationId) {\n          success\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation ReadNotification($notificationId: ID!) {\n      notification {\n        readNotification(notification_id: $notificationId) {\n          success\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ReadNotifications($input: ReadNotificationsInput) {\n      notification {\n        readNotifications(input: $input) {\n          success\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation ReadNotifications($input: ReadNotificationsInput) {\n      notification {\n        readNotifications(input: $input) {\n          success\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SeenUserNotifications {\n      notification {\n        seenUserNotifications {\n          success\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation SeenUserNotifications {\n      notification {\n        seenUserNotifications {\n          success\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation Accept($alias: String!) {\n      toc {\n        accept(alias: $alias) {\n          success\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation Accept($alias: String!) {\n      toc {\n        accept(alias: $alias) {\n          success\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Notifications($input: GetNotificationsInput!) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n          total\n          cursor\n          notifications {\n            id\n            message\n            read_at\n            created_at\n            actor {\n              display_name\n              avatar_url\n            }\n            sourceable_type\n            sourceable_id\n            subject {\n              ... on Post {\n                id\n              }\n              ... on Comment {\n                id\n                post_id\n              }\n            }\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Notifications($input: GetNotificationsInput!) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n          total\n          cursor\n          notifications {\n            id\n            message\n            read_at\n            created_at\n            actor {\n              display_name\n              avatar_url\n            }\n            sourceable_type\n            sourceable_id\n            subject {\n              ... on Post {\n                id\n              }\n              ... on Comment {\n                id\n                post_id\n              }\n            }\n          }\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  "): (typeof documents)["\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query customerContent {\n      customerContent {\n        content {\n          ... on Pattern {\n            id\n            sku\n            url\n            createdAt\n            updatedAt\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query customerContent {\n      customerContent {\n        content {\n          ... on Pattern {\n            id\n            sku\n            url\n            createdAt\n            updatedAt\n          }\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Posts($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Posts($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query loyalty {\n      loyalty {\n        points {\n          cursor\n          limit\n          points {\n            amount\n            created_at\n            type\n            description\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query loyalty {\n      loyalty {\n        points {\n          cursor\n          limit\n          points {\n            amount\n            created_at\n            type\n            description\n          }\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  "): (typeof documents)["\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  "): (typeof documents)["\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;